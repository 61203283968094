module.exports = {
  defaultTitle: 'Vab Kapoor',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Vab Kapoor',
  url: 'https://vabkapoor.com',
  legalName: 'Vab Kapoor',
  defaultDescription: 'I\'m Vab and I\'m a Software Engineer!',
  socialLinks: {
    // twitter: 'http://www.twitter.com/smakosh',
    github: 'https://github.com/vaibzzz123',
    linkedin: 'https://www.linkedin.com/in/vk2/',
    // instagram: 'https://instagram.com/smakosh19',
    // youtube: 'https://www.youtube.com/user/smakoshthegamer',
    // google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
  },
  googleAnalyticsID: '',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    // facebook: 'appId',
    // twitter: '@smakosh',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'vaib.kapoor15@gmail.com',
    phone: 'phone number',
  },
  foundingDate: '2020',
  recaptcha_key: '',
};
